import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { toast } from 'react-toastify'; // then this
import { validateNewsFormData } from './Validation';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import { sendRequest } from '../../../apis/APIs';
import { handleApiResponse } from '../../../apis/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../../common/BackButton';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Helmet } from 'react-helmet';


const AddEdit = () => {

	const [multiFormContnet, setMultiFormContnet] = useState({ 
		firstScreenEditorContent: "Let's start with your Basic Information", 
		lastScreenEditorContent1: "We're all done! I will get to work and send you the quotes for all of the products you requested information on. </br></br> My iQuote engine is getting some initial price points for some of your requests, including:",
		lastScreenEditorContent2: "Please schedule a call with me to review the quotes:" });
	const [errorsInfo, setErrorsInfo] = useState({ firstScreenEditorContent: "", lastScreenEditorContent1: "", lastScreenEditorContent2: "" });
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.common);

	useEffect(() => {
		getAddEditFormData();
	}, []);

	const handleEditorChange = (event, editor, editorId) => {
        const data = editor.getData();
        setMultiFormContnet(prevState => ({
            ...prevState,
            [editorId]: data,
        }));
    };


	const getAddEditFormData = async () => {
		try {
			dispatch(callCommonAction({ loading: true  ,breadCrumb: ['Embeddable Form Content', 'Manage'] }));
			const res = await sendRequest('/cms/embeddable-form-content', 'GET');
			dispatch(callCommonAction({ loading: false }));
			handleApiResponse(res);
			if (res.data.type === "success") {
				const hasValue = Object.values(res.data.data).some(value => value !== null && value !== undefined && value != "");
				if(hasValue){
					setMultiFormContnet(res.data.data);
				}
			}
		} catch (error) {
			console.error('Something went wrong',error);
			toast.error('Something went wrong');
			dispatch(callCommonAction({ loading: false }));
		}
	};

	/** Validation implemented here */
	const checkFormIsValid = async (fieldName) => {
		const res = validateNewsFormData(multiFormContnet, fieldName);
		setErrorsInfo(res.errors);
		return res.formVaildCheck;
	};

	/** Submit Form Data */
	const formSubmit = async (e) => {
		e.preventDefault();
		//console.log('CKEditor Content:', editorContent);
		if (await checkFormIsValid('')) {
			const formData = new FormData();
			Object.keys(multiFormContnet).forEach((key) => {
				formData.append(key, multiFormContnet[key]);
			});

			try {
				dispatch(callCommonAction({ loading: true }));
				const res = await sendRequest('/cms/embeddable-form-content', 'POST', formData);
				dispatch(callCommonAction({ loading: false }));
				handleApiResponse(res);
			} catch (error) {
				toast.error('Something went wrong');
				dispatch(callCommonAction({ loading: false }));
			}
		}
	};

	// Add or remove toolbar options as needed
	const config = {
		toolbar: ['bold', 'italic', 'link', 'undo', 'redo'],
	};


	return (
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			<Helmet>
				<title>iQuote | Embaded Form Content</title>
				<meta name="description" content="Description goes here" />
				<meta name="keywords" content="Game, Entertainment, Movies" />
			</Helmet>
			<div id="kt_content_container" className="container-xxl">
				<BackButton />
				<div className="card">
					<div className="card-header" id="kt_chat_contacts_header">
						<div className="card-title">
							<i className="ki-duotone ki-badge fs-1 me-2">
								<span className="path1"></span>
								<span className="path2"></span>
								<span className="path3"></span>
								<span className="path4"></span>
								<span className="path5"></span>
							</i>
							<h2>Embaded Form Content</h2>
						</div>
					</div>
					<div className="card-body table-frame pt-5">
						<form className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate onSubmit={formSubmit} encType="multipart/form-data">
							<div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">

								<div className="col">
									<div className="fv-row mb-7">
										<label className="fs-6 fw-semibold form-label mt-3">
											<span>First Screen Content</span>
										</label>
										{/* <textarea style={{height: '100px'}} onChange={handleChange} value={newsForm.des} className="form-control form-control-solid" name="des"></textarea> */}
										<CKEditor
											editor={ClassicEditor}
											onChange={(event, editor) => handleEditorChange(event, editor, 'firstScreenEditorContent')}
											data={multiFormContnet.firstScreenEditorContent}
											config={config}
										/>
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{errorsInfo.firstScreenEditorContent}</span>
											</div>
										</div>
									</div>
								</div>
								<div className="col">
									<div className="fv-row mb-7">
										<label className="fs-6 fw-semibold form-label mt-3">
											<span>Last Screen Content Section (1) </span>
										</label>
										{/* <textarea style={{height: '100px'}} onChange={handleChange} value={newsForm.des} className="form-control form-control-solid" name="des"></textarea> */}
										<CKEditor
											editor={ClassicEditor}
											onChange={(event, editor) => handleEditorChange(event, editor, 'lastScreenEditorContent1')}
											data={multiFormContnet.lastScreenEditorContent1}
											config={config}
										/>
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{errorsInfo.lastScreenEditorContent1}</span>
											</div>
										</div>
									</div>
								</div>

								<div className="col">
									<div className="fv-row mb-7">
										<label className="fs-6 fw-semibold form-label mt-3">
											<span>Last Screen Content Section (2)</span>
										</label>
										{/* <textarea style={{height: '100px'}} onChange={handleChange} value={newsForm.des} className="form-control form-control-solid" name="des"></textarea> */}
										<CKEditor
											editor={ClassicEditor}
											onChange={(event, editor) => handleEditorChange(event, editor, 'lastScreenEditorContent2')}
											data={multiFormContnet.lastScreenEditorContent2}
											config={config}
										/>
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{errorsInfo.lastScreenEditorContent2}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<button type="submit" id="kt_sign_up_submit" className="btn btn-primary me-2 mt-3" disabled={loading ? true : false}>
									{
										loading
											?
											<span className="indicator-progress" style={{ display: 'block' }}>
												Please wait...
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
											:
											<span className="indicator-label">Save</span>
									}
								</button>
							</div>
						</form>
					</div>

				</div>
			</div>
		</div>

	);
};
export default AddEdit;
