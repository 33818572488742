import STRINGS from '../../../common/strings/strings';

export function validateNewsFormData(data, fieldName) {
    const { firstScreenEditorContent, lastScreenEditorContent1, lastScreenEditorContent2 } = data; 
   
    let errorMsg = ''; let errors = {}; let formVaildCheck = true;
    
    if (firstScreenEditorContent === '' ) {
        errorMsg = firstScreenEditorContent === '' ? STRINGS.product.descriptionReq : '';
        errors.firstScreenEditorContent = errorMsg;
        formVaildCheck = false;
    }

    if (lastScreenEditorContent1 === '' ) {
        errorMsg = lastScreenEditorContent1 === '' ? STRINGS.product.descriptionReq : '';
        errors.lastScreenEditorContent1 = errorMsg;
        formVaildCheck = false;
    }

    if (lastScreenEditorContent2 === '' ) {
        errorMsg = lastScreenEditorContent2 === '' ? STRINGS.product.descriptionReq : '';
        errors.lastScreenEditorContent2 = errorMsg;
        formVaildCheck = false;
    }


    return { formVaildCheck: formVaildCheck, errors: errors };
}